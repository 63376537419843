/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.10 ship.glb 
*/

import React, { useRef } from "react";
import { MeshDistortMaterial, useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { BackSide, DoubleSide } from "three";

export function PirateShip(props) {
  const ref = useRef();
  const { nodes, materials } = useGLTF("/models/ship.glb");

  useFrame((state) => {
    const time = state.clock.getElapsedTime();

    ref.current.rotation.z = Math.sin(time) / 8;
  });
  return (
    <group ref={ref} {...props} dispose={null}>
      <mesh
        geometry={nodes.Ship.geometry}
        position={[0.321, -0.021, 0]}
        rotation={[0, 0, 0]}
      >
        <meshStandardMaterial
          attach={"material"}
          map={materials.PirateShip.map}
          toneMapped={false}
          side={DoubleSide}
        />
      </mesh>
      <group name="Main Mast" position={[-0.107, 0, 0.02]}>
        <Sail
          name="Sail 1"
          nodes={nodes}
          materials={materials}
          position={[0.5, 0, 0]}
          rotation={[0, 0, 0]}
          scale={1}
          color={props.color}
        />
        <Sail
          name="Sail 1"
          nodes={nodes}
          materials={materials}
          position={[0.4, 1.4, 0]}
          rotation={[0, 0, 0]}
          scale={[0.7, 0.73, 1]}
          color={props.color}
        />
      </group>
    </group>
  );
}

const Sail = ({ nodes, materials, ...props }) => {
  return (
    <mesh {...props} geometry={nodes.Sail.geometry} receiveShadow castShadow>
      <MeshDistortMaterial
        distort={0.2}
        speed={3}
        map={materials.PirateShip.map}
        toneMapped={false}
        side={BackSide}
      />
    </mesh>
  );
};

useGLTF.preload("/models/ship.glb");

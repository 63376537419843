import { useFrame } from "@react-three/fiber";
import { useRef } from "react";
import { WaveMaterial } from "../materials/waveShader";

export const Waves = (props) => {
  const waves = Array.from({ length: 30 });

  return (
    <group {...props}>
      {waves.map((wave, i) => (
        <Wave key={i} index={i} />
      ))}
    </group>
  );
};

const Wave = ({ index }) => {
  const ref = useRef();

  useFrame((state) => {
    ref.current.material.uniforms.u_time.value = state.clock.elapsedTime;
  });
  return (
    <mesh position={[0, 0, -index * 10]} ref={ref} receiveShadow>
      <planeGeometry args={[300, 10, 600, 1]} />
      <WaveMaterial index={index} />
    </mesh>
  );
};

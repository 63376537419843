import { PerspectiveCamera, RenderTexture, Text } from "@react-three/drei";
import { useMemo } from "react";
import { Color, DoubleSide, MeshStandardMaterial } from "three";
import CustomShaderMaterial from "three-custom-shader-material";

export const PageMaterial = (props) => {
  return <meshStandardMaterial color={"white"} side={DoubleSide} />;
};

export const ForestMaterial = ({ color }) => {
  return useMemo(() => {
    return (
      <CustomShaderMaterial
        baseMaterial={MeshStandardMaterial}
        //uniforms
        uniforms={{ color: { value: color } }}
        // vertex shader
        vertexShader={
          /* glsl */ `
        varying vec4 vPos;
        varying vec2 vUV;
        varying float depthTest;

         #include <packing>

          void main() {
            vUV = uv;


            vPos = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            gl_Position = vPos;

          }
          
        `
        }
        fragmentShader={
          /* glsl */ `
          uniform vec3 color;
       	  varying vec4 vPos;
          varying vec2 vUV;
          varying float depthTest;


         
  
          void main() {
            //get the vec2 from vec3 vPos
            vec2 vCoords = vPos.xy;
            vCoords /= vPos.w;
            vCoords = vCoords * 0.5 + 0.5;
            float distance = vPos.z;
          
            vec2 uv = fract( vCoords );
            float yPos = clamp(0.0, 1.0, uv.y + 0.75);
            vec3 colorUp = vec3(1.0, 1.0, 1.0);

            //gradient in y-direction
            vec3 uplight = mix(colorUp, color, yPos);
            //mix with a distance tint;
            vec3 tint = vec3(0.01,0.01,0.01);
            vec3 depth = mix(uplight, tint, -distance);


            csm_DiffuseColor = vec4( depthTest, 0.0, 0.0, 1.0 );
          }
        `
        }
      />
    );
  }, [color]);
};
//Remember to call this inside a meshStandardMaterial or something - its just a map!
export const TextPageMaterial = (props) => {
  const pageColor = new Color(0xeeede9);
  return (
    <RenderTexture attach="map">
      <color attach="background" args={[pageColor]} />
      <PerspectiveCamera
        makeDefault
        manual
        aspect={1 / 1}
        position={[-5, 0, 30]}
      />
      <group scale={[1, -1, 1]}>
        {/* <Text
          position={[0, 3.2, 0]}
          maxWidth={9}
          fontSize={0.7}
          font={"/fonts/Montserrat-SemiBold.ttf"}
          color={"black"}
        >
          {`Chapter ${props.chapter}`}
        </Text> */}
        <Text
          position={[0, -0.7, 0]}
          maxWidth={13}
          fontSize={1.25}
          font={"/fonts/Montserrat-SemiBold.ttf"}
          color={"black"}
        >
          {props.text}
        </Text>
      </group>
    </RenderTexture>
  );
};

export const BookMaterial = (props) => {
  return <meshStandardMaterial color="#8C3624" />;
};

import { Billboard, Line, Text } from "@react-three/drei";
import { useMemo } from "react";
import { CatmullRomCurve3, Vector3 } from "three";
import useMediaQuery from "../hooks/useMediaQuery";

const LINE_NB_POINTS = 12000;

export const followPoints = [
  //z progress is steady 10 blocks...
  new Vector3(-1.5, -1.7, 2),
  new Vector3(0, -0.5, -5),
  new Vector3(0.9, -0.2, -18),
  new Vector3(1, 0, -24),
  new Vector3(-1, 0, -30),
  new Vector3(-5, 0, -50),
  new Vector3(-1, 3, -108),
  // new Vector3(-1, 1, -113),
  new Vector3(-1, -1, -114),
];

export const followPointsMobile = [
  //z progress is steady 10 blocks...
  new Vector3(-1, 0, 6),
  new Vector3(0, -0.5, -5),
  new Vector3(0.95, -0.2, -18),
  new Vector3(1, 0, -24),
  new Vector3(0, 0, -30),
  new Vector3(-1, 0, -50),
  new Vector3(-1, 3, -108),
  // new Vector3(-1, 1, -113),
  new Vector3(-1, -1, -114),
];

export const targetPoints = [
  //z progress is steady 10 blocks...
  new Vector3(-1.5, -0.75, -5),
  new Vector3(1, -0.2, -18),
  new Vector3(1, 0, -24),
  new Vector3(1, 0, -30),
  new Vector3(0, 0, -50),
  new Vector3(-1, -1, -108),
  new Vector3(-1, -4, -115),
  new Vector3(-1, -12, -114),
];

export function useNoOfPoints() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return isMobile ? followPointsMobile.length : followPoints.length;
}

export function useCreateCurve(desktop, mobile) {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const curve = useMemo(() => {
    return new CatmullRomCurve3(
      isMobile ? mobile : desktop,
      //Is it closed - NO
      false,
      "catmullrom",
      //tension
      0.5
    );
  }, [desktop, isMobile, mobile]);

  return curve;
}

export function useLinePoints(curve) {
  return useMemo(() => {
    return curve.getPoints(LINE_NB_POINTS);
  }, [curve]);
}

export const CurvePoints = ({ points }) => {
  return (
    <group position={[0, 0, 0]}>
      {points.map((position, i) => {
        return (
          <mesh position={position} key={i}>
            <sphereGeometry args={[0.1, 24]} />
            <Billboard>
              <Text
                scale={[0.1, 0.1, 0.1]}
                position={[0, 0.23, 0]}
                anchorX={"center"}
              >
                Position: {Math.round(position.x)}, {Math.round(position.y)},
                {Math.round(position.z)}
              </Text>
            </Billboard>
            <meshBasicMaterial color={"orange"} />
          </mesh>
        );
      })}
    </group>
  );
};

export const LineWithPoints = ({ desktop, mobile, color }) => {
  const linePoints = useLinePoints(useCreateCurve(desktop, mobile));
  return (
    <>
      <Line
        points={linePoints}
        color={color}
        opacity={0.8}
        transparent
        lineWidth={5}
      />
      {desktop.map((position, i) => {
        return (
          <mesh position={position} key={i}>
            <sphereGeometry args={[0.1, 24]} />
            <Billboard>
              <Text
                scale={[0.1, 0.1, 0.1]}
                position={[0, 0.23, 0]}
                anchorX={"center"}
              >
                Position: {Math.round(position.x)}, {Math.round(position.y)},
                {Math.round(position.z)}
              </Text>
            </Billboard>
            <meshBasicMaterial color={"orange"} />
          </mesh>
        );
      })}
    </>
  );
};

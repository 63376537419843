/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.10 cloud.glb 
*/

import React from "react";
import { useGLTF } from "@react-three/drei";

export function Cloud(props) {
  const { nodes, materials } = useGLTF("/models/cloud.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.Clouds_Simple.geometry}
        position={[-0.009, 0.657, -0.015]}
      >
        <meshStandardMaterial
          map={materials["04 - Default"].map}
          attach={"material"}
          toneMapped={false}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload("/models/cloud.glb");

import { MeshPortalMaterial, useScroll } from "@react-three/drei";
import { Suspense, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { DoubleSide } from "three";

export const Portal = (props) => {
  const [blend, setBlend] = useState(0);
  const scroll = useScroll();
  useFrame(() => {
    //set blending FBO
    if (scroll.offset > props.blendThreshold) setBlend(1);
    else setBlend(0);
  });
  return (
    <>
      <mesh {...props}>
        <MeshPortalMaterial blend={blend} worldUnits={true} side={DoubleSide}>
          <Suspense fallback={null}>
            {props.children}
            {/* <LineWithPoints desktop={followPoints} mobile={followPointsMobile} />
          <LineWithPoints
            desktop={targetPoints}
            mobile={targetPoints}
            color={"orange"}
          /> */}
          </Suspense>
        </MeshPortalMaterial>
      </mesh>
    </>
  );
};

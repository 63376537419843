import { useMemo } from "react";
import { MeshPhysicalMaterial } from "three";
import { Color } from "three";

import CustomShaderMaterial from "three-custom-shader-material";

export const WaveMaterial = ({ index }) => {
  return useMemo(() => {
    return (
      <CustomShaderMaterial
        baseMaterial={MeshPhysicalMaterial}
        //uniforms
        uniforms={{
          u_time: { value: 0 },
          color: { value: new Color("#1B69DE") },
          tint: { value: new Color("#5995EF") },
          index: { value: index },
          wavelength: { value: 1.5 },
        }}
        vertexShader={
          /* glsl */ `
        uniform float u_time;
        uniform float index;
        uniform float wavelength;
        varying vec3 pos;
        varying float viewZ;
        varying vec2 vUV;
         #include <packing>

        float getViewZ( const in float depth ) {
		    return perspectiveDepthToViewZ( depth * 2.0, 22.0, 36.0 );
        }
        
        void main() {
            viewZ = getViewZ( -(modelViewMatrix * vec4(position.xyz, 1.0)).z);
            vUV = uv;
            //wave speed and in-out action
            float shoreWave = sin(u_time * 2.0 + index * 10.0);
            //wavelength, number of waves
            float crests =  sin(position.x * wavelength) / 3.0;
            float motion = crests * shoreWave;
            //Waves that go up and down to the horizon
            float horW =  sin(u_time + index * 0.610);

            //Combine
            float x = motion * horW;

            float s = sin(u_time - index ) / 2.0;
            csm_PositionRaw = projectionMatrix *  modelViewMatrix * (vec4(position.x, position.y + x, position.z, 1.0) + s);

        }
        `
        }
        fragmentShader={
          /* glsl */ `
            uniform vec3 color;
            uniform vec3 tint;
            uniform float index;
            varying float viewZ;
            varying vec2 vUV;

              float random( const in float x) {
                return fract(sin(x)*100000.312);
             }
        
            
            void main() {

              vec3 black = vec3(0.0,0.0,0.0);
              float  normalIndx = (index /30.0);
              vec3 indexedColor = vec3(color.x + normalIndx, color.y + normalIndx, color.z + normalIndx);
              vec3 depth = mix(indexedColor, tint, viewZ );
              //make a gradient in y:
              vec3 grad = vec3(mix(black, depth, (vUV.y - 0.3)));
              //float layerDiff = random(normalIndx * 10.0);
              //vec3 layering = depth + vec3(layerDiff, layerDiff, layerDiff + 0.8);
              
              csm_DiffuseColor = vec4(grad, 1.0);
            }
        `
        }
        toneMapped={false}
      />
    );
  }, [index]);
};

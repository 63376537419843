import { Billboard, Text, useScroll } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { Suspense, useRef } from "react";
import { MathUtils } from "three";

export const Copy = ({
  children,
  position,
  fontSize,
  fadeThreshold,
  color,
}) => {
  const ref = useRef();
  const scroll = useScroll();

  useFrame(() => {
    if (scroll.offset > fadeThreshold) {
      ref.current.fillOpacity = MathUtils.lerp(
        0.0,
        1.0,
        scroll.range(fadeThreshold, 0.05)
      );
    }
  });
  const fontProps = {
    font: "/fonts/Montserrat-SemiBold.ttf",
    fontSize: fontSize || 0.15,
    letterSpacing: -0.05,
    lineHeight: 1.2,
    color: color,
    maxWidth: "3",
    fillOpacity: 0,
    "material-toneMapped": false,
  };
  return (
    <Suspense fallback={null}>
      <Billboard>
        <Text
          ref={ref}
          position={position}
          {...fontProps}
          children={children}
        />
      </Billboard>
    </Suspense>
  );
};

export const Logo = (props) => {
  return (
    <svg
      className="logo"
      height="100%"
      viewBox="0 0 957 163"
      fill="none"
      preserveAspectRatio="xMinYMin meet"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M623 0L540 13.6806L623 32.7575V0Z" fill="#004B7B" />
      <path d="M623 0L706 13.6806L623 32.7575V0Z" fill="#0065A0" />
      <path d="M623 97.981L540 123.329L623 163V97.981Z" fill="#61CD6C" />
      <path
        d="M623 32.7576L540 13.6807V123.329L623 97.9809V32.7576Z"
        fill="#33A03E"
      />
      <path d="M623 97.981L706 123.329L623 163V97.981Z" fill="#AF0A38" />
      <path
        d="M623 32.7576L706 13.6807V123.329L623 97.9809V32.7576Z"
        fill="#D2184D"
      />
      <path
        d="M402.708 106.527C401.699 106.527 400.928 105.696 400.928 104.747V32.6169C400.928 31.6085 401.699 30.8374 402.708 30.8374H419.969C420.918 30.8374 421.748 31.6085 421.748 32.6169V86.418C421.748 87.5451 422.638 88.4348 423.765 88.4348H454.314C455.322 88.4348 456.093 89.206 456.093 90.2144V104.807C456.093 105.756 455.322 106.586 454.314 106.586H402.708V106.527Z"
        fill="white"
      />
      <path
        d="M0 68.0295C0 63.4027 0.889765 58.8353 2.60998 54.3864C4.33019 49.9376 6.88085 45.904 10.262 42.345C13.5837 38.7859 17.6767 35.9387 22.4221 33.6846C27.1675 31.4898 32.6247 30.3628 38.7344 30.3628C46.0305 30.3628 52.3775 31.8457 57.7754 34.8709C62.6394 37.5996 66.3171 41.0993 68.9271 45.4295C69.4017 46.2006 69.2237 47.2683 68.4526 47.8022L55.5806 56.9371C54.7502 57.5303 53.5638 57.293 53.03 56.4032C52.4368 55.3355 51.7843 54.3864 51.0132 53.6153C49.8268 52.429 48.5218 51.4206 47.0982 50.7087C45.6746 49.9969 44.1917 49.5224 42.6494 49.2258C41.1071 48.9292 39.6242 48.8106 38.2006 48.8106C35.2347 48.8106 32.6247 49.4038 30.4893 50.5901C28.2945 51.7765 26.515 53.3187 25.1507 55.1576C23.7864 57.0557 22.7187 59.1912 22.0662 61.5639C21.4137 63.9366 21.0578 66.3093 21.0578 68.682C21.0578 71.292 21.473 73.8426 22.2441 76.2747C23.0152 78.7067 24.1423 80.8421 25.6845 82.681C27.1675 84.5198 29.0063 86.0028 31.2011 87.1298C33.3958 88.2568 35.8279 88.7907 38.4972 88.7907C39.9208 88.7907 41.4037 88.6127 42.8867 88.3161C44.3696 88.0196 45.7932 87.4857 47.2169 86.7146C48.5812 85.9434 49.8268 84.9944 50.9539 83.808C51.6657 83.0369 52.2589 82.2064 52.7927 81.198C53.2673 80.3083 54.3943 79.9524 55.2247 80.4862L69.1644 88.7907C69.9355 89.2652 70.2321 90.2736 69.8169 91.1041C68.6898 93.2988 67.2069 95.2563 65.3087 97.0358C62.9953 99.2306 60.3854 101.069 57.4195 102.552C54.4536 104.035 51.3098 105.162 47.9287 105.993C44.5476 106.764 41.2851 107.179 38.0819 107.179C32.4468 107.179 27.3454 106.052 22.6593 103.798C17.9732 101.544 13.999 98.5781 10.6179 94.9004C7.23675 91.2227 4.62678 87.0112 2.78793 82.3251C0.949082 77.639 0 72.8342 0 68.0295Z"
        fill="white"
      />
      <path
        d="M116.144 107.179C110.39 107.179 105.17 106.052 100.484 103.857C95.798 101.663 91.7644 98.7561 88.4426 95.197C85.1208 91.6379 82.5108 87.4857 80.672 82.8589C78.8331 78.2321 77.884 73.4867 77.884 68.5634C77.884 63.5807 78.8331 58.776 80.7906 54.1492C82.6888 49.5224 85.4174 45.4295 88.8578 41.9297C92.2982 38.43 96.3911 35.5827 101.137 33.5066C105.882 31.4305 111.043 30.3628 116.678 30.3628C122.432 30.3628 127.652 31.4898 132.338 33.6846C137.024 35.8793 141.057 38.7859 144.379 42.4043C147.701 46.0227 150.311 50.1749 152.09 54.8017C153.929 59.4284 154.819 64.1739 154.819 68.9786C154.819 73.9613 153.87 78.7067 151.912 83.3335C150.014 87.9009 147.345 91.9938 143.905 95.4936C140.464 98.9933 136.431 101.841 131.685 103.976C126.88 106.111 121.72 107.179 116.144 107.179ZM98.9418 68.8006C98.9418 71.3513 99.2977 73.8426 100.01 76.2153C100.721 78.588 101.789 80.7235 103.213 82.5623C104.636 84.4012 106.416 85.8841 108.611 87.0705C110.805 88.1975 113.356 88.7907 116.322 88.7907C119.288 88.7907 121.898 88.1975 124.092 87.0112C126.287 85.8248 128.067 84.2825 129.49 82.4437C130.855 80.5455 131.922 78.4101 132.575 75.9781C133.227 73.5461 133.583 71.114 133.583 68.6227C133.583 66.072 133.227 63.5807 132.516 61.208C131.804 58.8353 130.736 56.6998 129.253 54.9203C127.77 53.0815 125.991 51.6578 123.796 50.5308C121.601 49.4038 119.11 48.8699 116.144 48.8699C113.178 48.8699 110.568 49.4631 108.433 50.5901C106.238 51.7172 104.458 53.2594 103.035 55.0983C101.611 56.9964 100.543 59.1319 99.8909 61.5046C99.2977 63.8773 98.9418 66.3093 98.9418 68.8006Z"
        fill="white"
      />
      <path
        d="M168.877 104.451V32.9135C168.877 31.7865 169.827 30.8374 170.954 30.8374H203.638C207.315 30.8374 210.756 31.6085 213.9 33.1508C217.043 34.6931 219.713 36.6505 221.967 39.1419C224.221 41.5739 225.941 44.4211 227.246 47.565C228.551 50.7088 229.144 53.912 229.144 57.1151C229.144 61.5046 228.195 65.6569 226.238 69.4532C224.339 73.2495 221.67 76.3934 218.23 78.7661L232.466 103.502C233.237 104.866 232.288 106.586 230.686 106.586H212.001C211.23 106.586 210.578 106.171 210.222 105.518L198.299 84.7571C197.765 83.8674 196.816 83.3335 195.808 83.3335H191.062C190.291 83.3335 189.639 83.986 189.639 84.7571V104.51C189.639 105.637 188.69 106.586 187.563 106.586H170.894C169.827 106.527 168.877 105.578 168.877 104.451ZM189.698 63.4621C189.698 64.3519 190.469 65.123 191.359 65.123H202.807C204.112 65.123 205.298 64.4112 206.366 62.9876C207.493 61.5639 208.027 59.5472 208.027 56.9965C208.027 54.3865 207.375 52.3697 206.129 50.9461C204.824 49.5818 203.578 48.87 202.273 48.87H191.359C190.469 48.87 189.698 49.6411 189.698 50.5309V63.4621Z"
        fill="white"
      />
      <path
        d="M266.989 72.5968V104.272C266.989 105.518 265.98 106.526 264.735 106.526H248.422C247.177 106.526 246.168 105.518 246.168 104.272V33.0319C246.168 31.7862 247.177 30.7778 248.422 30.7778H260.879C261.828 30.7778 262.718 31.1931 263.311 31.9642L290.656 66.6057C291.368 67.4955 292.733 66.9616 292.733 65.8939V33.0319C292.733 31.7862 293.741 30.7778 294.987 30.7778H311.299C312.545 30.7778 313.553 31.7862 313.553 33.0319V104.272C313.553 105.518 312.545 106.526 311.299 106.526H298.486C297.537 106.526 296.648 106.111 296.054 105.34L269.006 71.885C268.412 70.9952 266.989 71.5291 266.989 72.5968Z"
        fill="white"
      />
      <path
        d="M385.861 91.7566V103.205C385.861 105.044 384.378 106.527 382.54 106.527H335.263C333.425 106.527 331.942 105.044 331.942 103.205V34.1592C331.942 32.3203 333.425 30.8374 335.263 30.8374H381.591C383.429 30.8374 384.912 32.3203 384.912 34.1592V45.6075C384.912 47.4463 383.429 48.9293 381.591 48.9293H354.482C353.533 48.9293 352.762 49.7004 352.762 50.6495V57.8269C352.762 58.776 353.533 59.5471 354.482 59.5471H376.904C378.743 59.5471 380.226 61.0301 380.226 62.8689V73.0123C380.226 74.8511 378.743 76.334 376.904 76.334H354.482C353.533 76.334 352.762 77.1052 352.762 78.0543V86.5367C352.762 87.4858 353.533 88.2569 354.482 88.2569H382.54C384.378 88.3755 385.861 89.9178 385.861 91.7566Z"
        fill="white"
      />
      <path
        d="M470.389 106.527C469.381 106.527 468.609 105.696 468.609 104.747V32.6169C468.609 31.6085 469.381 30.8374 470.389 30.8374H487.65C488.659 30.8374 489.43 31.6085 489.43 32.6169V86.418C489.43 87.5451 490.32 88.4348 491.447 88.4348H521.995C522.944 88.4348 523.775 89.206 523.775 90.2144V104.807C523.775 105.756 522.944 106.586 521.995 106.586H470.389V106.527Z"
        fill="white"
      />
      <path
        d="M795.522 87.1299C795.522 90.5703 794.81 93.4768 793.387 95.9089C791.963 98.3409 790.005 100.358 787.514 101.959C785.023 103.502 782.116 104.688 778.794 105.4C775.473 106.171 771.973 106.527 768.295 106.527H734.543C732.645 106.527 731.103 104.984 731.103 103.086V34.2778C731.103 32.3797 732.645 30.8374 734.543 30.8374H775.057C777.667 30.8374 780.04 31.4306 782.176 32.5576C784.311 33.6847 786.15 35.1676 787.633 37.0064C789.116 38.8453 790.302 40.8621 791.073 43.1755C791.904 45.4889 792.319 47.8616 792.319 50.2936C792.319 53.8527 791.429 57.2338 789.59 60.4962C787.751 63.7587 785.082 66.1907 781.404 67.7923C785.735 69.0973 789.175 71.292 791.666 74.4359C794.276 77.5204 795.522 81.7913 795.522 87.1299ZM751.924 50.2343V58.4201C751.924 59.3692 752.695 60.1403 753.644 60.1403H766.1C767.524 60.1403 768.829 59.7251 770.015 58.954C771.202 58.1828 771.795 56.5813 771.795 54.2679C771.795 52.1918 771.32 50.7088 770.312 49.8191C769.304 48.9293 768.117 48.5141 766.812 48.5141H753.644C752.695 48.5141 751.924 49.2852 751.924 50.2343ZM774.405 82.859C774.405 81.0201 773.93 79.4779 772.922 78.2322C771.914 76.9865 770.668 76.3934 769.066 76.3934H753.644C752.695 76.3934 751.924 77.1645 751.924 78.1136V87.3671C751.924 88.3162 752.695 89.0873 753.644 89.0873H768.355C770.075 89.0873 771.498 88.5535 772.685 87.4858C773.812 86.3587 774.405 84.8165 774.405 82.859Z"
        fill="white"
      />
      <path
        d="M844.162 107.179C838.409 107.179 833.189 106.052 828.503 103.857C823.817 101.663 819.783 98.7561 816.461 95.197C813.139 91.6379 810.529 87.4857 808.691 82.8589C806.852 78.2321 805.903 73.4867 805.903 68.5634C805.903 63.5807 806.852 58.776 808.809 54.1492C810.707 49.5224 813.436 45.4295 816.876 41.9297C820.317 38.43 824.41 35.5827 829.155 33.5066C833.901 31.4305 839.061 30.3628 844.696 30.3628C850.45 30.3628 855.67 31.4898 860.356 33.6846C865.042 35.8793 869.076 38.7859 872.398 42.4043C875.719 46.0227 878.329 50.1749 880.109 54.8017C881.948 59.4284 882.838 64.1739 882.838 68.9786C882.838 73.9613 881.889 78.7067 879.931 83.3335C877.974 87.9602 875.364 91.9938 871.923 95.4936C868.483 98.9933 864.449 101.841 859.704 103.976C854.958 106.111 849.798 107.179 844.162 107.179ZM826.96 68.8006C826.96 71.3513 827.316 73.8426 828.028 76.2153C828.74 78.588 829.808 80.7235 831.231 82.5623C832.655 84.4012 834.434 85.8841 836.629 87.0705C838.824 88.1975 841.375 88.7907 844.34 88.7907C847.306 88.7907 849.916 88.1975 852.111 87.0112C854.306 85.8248 856.085 84.2825 857.509 82.4437C858.873 80.5455 859.941 78.4101 860.594 75.9781C861.246 73.5461 861.602 71.114 861.602 68.6227C861.602 66.072 861.246 63.5807 860.534 61.208C859.822 58.8353 858.755 56.6998 857.272 54.9203C855.789 53.0815 854.009 51.6578 851.814 50.5308C849.62 49.4038 847.128 48.8699 844.162 48.8699C841.197 48.8699 838.587 49.4631 836.451 50.5901C834.256 51.7172 832.477 53.2594 831.053 55.0983C829.63 56.9964 828.562 59.1319 827.909 61.5046C827.316 63.8773 826.96 66.3093 826.96 68.8006Z"
        fill="white"
      />
      <path
        d="M909.175 32.261L921.453 53.6154C921.75 54.0899 922.462 54.0899 922.699 53.6154L934.978 32.261C935.512 31.3713 936.461 30.8374 937.469 30.8374H954.434C956.036 30.8374 956.985 32.6169 956.095 33.9812L933.139 68.86C932.961 69.0973 932.961 69.3939 933.139 69.6905L955.265 103.502C956.154 104.866 955.205 106.645 953.604 106.645H936.639C935.571 106.645 934.622 106.112 934.088 105.162L922.64 84.9351C922.343 84.4606 921.631 84.4606 921.335 84.9351L909.886 105.162C909.353 106.052 908.404 106.645 907.336 106.645H890.252C888.651 106.645 887.702 104.866 888.591 103.502L910.717 69.6905C910.895 69.4532 910.895 69.1566 910.717 68.86L887.761 33.9812C886.871 32.6169 887.82 30.8374 889.422 30.8374H906.505C907.692 30.8374 908.641 31.3713 909.175 32.261Z"
        fill="white"
      />
    </svg>
  );
};

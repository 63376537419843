/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.10 desk-stuff.glb 
*/

import React from "react";
import { Merged, useGLTF } from "@react-three/drei";

export function DeskStuff(props) {
  const { nodes, materials } = useGLTF("/models/desk-stuff.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.NewBeats.geometry}
        material={materials.Baker}
        receiveShadow
      />

      <Merged meshes={nodes}>
        {({ Pencil }) => (
          <>
            <Pencil
              rotation={[-Math.PI / 2, 0, -0.08]}
              position={[0.58, 0, 2.25]}
            />
            <Pencil
              rotation={[-Math.PI / 2, 0, 0.08]}
              position={[0.545, 0.04, 2.36]}
            />
            <Pencil
              rotation={[-Math.PI / 2, 0, 0.08]}
              position={[-2, 0.13, 0.99]}
            />
            {/* <Pencil
              rotation={[Math.PI / 2, 0, -0.3]}
              position={[-1.65, 0, -2.58]}
            />
            <Pencil
              rotation={[Math.PI / 2, 0, -0.13]}
              position={[-1.66, 0, -2.47]}
            /> */}
          </>
        )}
      </Merged>

      {/* <mesh
        geometry={nodes.TablePlane.geometry}
        material={materials.Table_P}
        position={[1.722, 0, 0.051]}
        receiveShadow
        castShadow
      /> */}
      <mesh
        geometry={nodes.TableDrawings.geometry}
        material={materials.Sketches}
        position={[2.24, 0.025, 2.239]}
      />
      <mesh
        geometry={nodes.WaterBottle.geometry}
        material={materials["Water Bottle"]}
        position={[-1.654, 0, 1.745]}
        rotation={[Math.PI / 2, 0, 1.01]}
        scale={[0.191, 0.191, 0.227]}
        receiveShadow
        castShadow
      />
      <mesh
        geometry={nodes.Lamp.geometry}
        material={materials["20 - Default"]}
        position={[-1.311, 0, -0.575]}
        rotation={[0, -0.199, 0]}
        scale={100}
      />
      <mesh
        geometry={nodes.Matt.geometry}
        material={materials["Mat_P.001"]}
        position={[0.64, 0.001, 0.688]}
        receiveShadow
      />
      <mesh
        geometry={nodes.Laptop.geometry}
        material={materials.Lpatop_Physical}
        position={[3.866, 0, -0.42]}
        rotation={[0, -0.436, 0]}
      />
      <mesh
        geometry={nodes.Phone.geometry}
        material={materials.Phone}
        position={[0.979, 0, 2.156]}
        rotation={[0, -0.262, 0]}
      />
      <mesh
        geometry={nodes.HeadphoneBook.geometry}
        material={materials.BookHeaphone}
        position={[3.382, 0.132, 1.071]}
        rotation={[0, -Math.PI / 6, 0]}
        scale={5.318}
      />
      <mesh
        geometry={nodes.LH_Book.geometry}
        material={materials.BookLH}
        position={[-2.127, 0.103, 0.566]}
        rotation={[0, -0.112, 0]}
        scale={5.318}
      />
      <mesh
        geometry={nodes.Cup.geometry}
        material={materials.Cup_P}
        position={[2.85, 0.328, 0.384]}
        rotation={[0, -0.872, Math.PI]}
        scale={4.992}
      />
    </group>
  );
}

useGLTF.preload("/models/desk-stuff.glb");

import { Html, useScroll } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useState } from "react";
import useMediaQuery from "../hooks/useMediaQuery";
import { Logo } from "./logo";

export const ScrollText = ({ modal, setModal, info, setInfo }) => {
  const scroll = useScroll();
  const mobile = useMediaQuery("(max-width: 480px)");
  const [page, setPage] = useState(1);

  const between = (x, min, max) => {
    return x >= min && x <= max;
  };

  //Using useFrame's camera reference seems to update better?!
  useFrame(() => {
    setPage(scroll.pages * scroll.offset);
  });
  return (
    <group
      position={[]} /* Dont ask why this works! But, it destroys the transform attached to the HTML portal that shouldnt be there anyway */
    >
      <Html portal={{ current: scroll.fixed }} wrapperClass="hidden">
        <div
          className={`container light ${
            between(page, 0, 0.1) && !modal && !info ? "visible" : "not"
          }`}
        >
          <div className="intro">
            <h1>Empowering you</h1>
            <h2>
              to tell engaging stories through cutting-edge digital experiences
            </h2>
            <div className="adendum">
              <p className="down-arrow" style={{ paddingBottom: "1rem" }}>
                scroll to enter
              </p>
              {/* <svg
                className="down-arrow"
                width="16"
                height="34"
                viewBox="0 0 16 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.46339 0.322678V32.5M8.46339 32.5L1.10394 25.6856M8.46339 32.5L14.896 25.6856"
                  stroke="white"
                  strokeWidth="1.75"
                />
              </svg> */}
            </div>
          </div>
        </div>
        {mobile ? (
          <>
            <div
              className={`container light ${
                between(page, 0.405, 0.89) && !modal && !info
                  ? "visible"
                  : "not"
              }`}
            >
              <div className="intro center">
                <h2>
                  We know that complex stories
                  <br /> are difficult to tell.
                </h2>
              </div>
            </div>

            <div
              className={`container light ${
                between(page, 2.179, 2.56) && !modal && !info
                  ? "visible"
                  : "not"
              }`}
            >
              <div className="intro center">
                <h2>
                  That’s why Cornell Box work alongside
                  <br /> agency partners...
                </h2>
              </div>
            </div>
          </>
        ) : null}
        {/* <div
          className={`container light center ${
            between(page, 2.65, 4.4) && !modal && !info ? "visible" : "not"
          }`}
        >
          <div className="intro ">
            <h2>to reduce complexity,</h2>
          </div>
        </div>
        <div
          className={`container light center ${
            between(page, 3, 4.4) && !modal && !info ? "visible" : "not"
          }`}
        >
          <div className="intro ">
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>select hardware,</h2>
          </div>
        </div>
        <div
          className={`container light center ${
            between(page, 3.35, 4.4) && !modal && !info ? "visible" : "not"
          }`}
        >
          <div className="intro ">
            <h2>
              <br />
              <br />
              <br />
              <br />
              build software,
            </h2>
          </div>
        </div>
        <div
          className={`container light center ${
            between(page, 3.9, 4.4) && !modal && !info ? "visible" : "not"
          }`}
        >
          <div className="intro ">
            <h2>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br /> and add content.
            </h2>
          </div>
        </div> */}
        <div
          className={`container light ${
            between(page, 4.8, 5.3) && !modal && !info ? "visible" : "not"
          }`}
        >
          <div className="intro">
            <h2>To tell stories that audiences understand.</h2>
          </div>
        </div>

        <div
          className={`container light center ${
            between(page, 5.5, 5.85) && !modal && !info ? "visible" : "not"
          }`}
        >
          <div className="intro">
            <h2>
              Simplification, understanding, <br />
              storytelling, digital experience.
            </h2>
          </div>
        </div>

        <div
          className={`container light center ${
            between(page, 6.0, 6.75) && !modal && !info ? "visible" : "not"
          }`}
        >
          <div className="intro">
            <h2>
              That's how we deliver complex stories <br /> that demand attention
              in a busy world.
            </h2>
          </div>
        </div>

        <div
          className={`container modal center ${
            between(page, 6.922, 9) ? "visible" : "not"
          }`}
        >
          <Contacts />
        </div>
        <div className={`container modal center ${modal ? "visible" : "not"}`}>
          <Contacts />
        </div>
        <div className={`container modal center ${info ? "visible" : "not"}`}>
          <Info />
        </div>
      </Html>
    </group>
  );
};

const Contacts = () => {
  return (
    <div className="intro info">
      <>
        <Logo />
        <br />
        <a
          href="mailto:roddy@cornellbox.studio"
          aria-label="mail"
          target="_blank"
          rel="noreferrer"
        >
          <h2>roddy@cornellbox.studio</h2>
        </a>
        <h2>07725 235265</h2>
        <a
          href="mailto:sam@cornellbox.studio"
          aria-label="mail"
          target="_blank"
          rel="noreferrer"
        >
          <h2>sam@cornellbox.studio</h2>
        </a>
        <h2>07949 640514</h2>
      </>
    </div>
  );
};

const Info = () => {
  return (
    <div className="intro info">
      <h3>We know that complex stories are difficult to tell.</h3>
      <h3>They need simplification to generate audience understanding.</h3>
      <h3>
        That's why Cornell Box work alongside agency partners to reduce
        complexity,
      </h3>
      <h3>select hardware, build software, and add content.</h3>
      <h3>To tell stories that audiences understand.</h3>
      <h3>Simplification, understanding, storytelling, digital experience.</h3>
      <h3>
        That's how we deliver complex stories that demand attention in a busy
        world.
      </h3>

      <Logo />
    </div>
  );
};

import { useScroll } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useLayoutEffect } from "react";
import {
  followPoints,
  followPointsMobile,
  targetPoints,
  useCreateCurve,
  useLinePoints,
} from "./data/curve";

import useMediaQuery from "./hooks/useMediaQuery";

const Camera = () => {
  const modifier = 30;

  const { viewport, camera } = useThree();
  const mobile = useMediaQuery("(max-width: 768px)");

  const scroll = useScroll();

  const curve = useCreateCurve(followPoints, followPointsMobile);
  const targetCurve = useCreateCurve(targetPoints, targetPoints);

  const linePoints = useLinePoints(curve);
  const targetLinePoints = useLinePoints(targetCurve);

  useLayoutEffect(() => {
    if (viewport.aspect > 1)
      camera.fov = Math.min(viewport.aspect * modifier, 50);
    else if (mobile) camera.fov = 70;
    else camera.fov = modifier;

    //adaptive pixelratio for large monitors
  }, [camera, mobile, viewport.aspect]);

  //setup regress when the mouse scroll event happens...see Canvas AdaptiveDPR
  // useLerpedMouse();

  useFrame((state) => {
    const curPointIndex = Math.min(
      Math.round(scroll.offset * linePoints.length),
      linePoints.length - 1
    );
    // console.log(scroll.offset);
    const targetPointIndex = Math.min(
      Math.round(scroll.offset * targetLinePoints.length),
      targetLinePoints.length - 1
    );

    const curPoint = linePoints[curPointIndex];
    const targetPoint = targetLinePoints[targetPointIndex];

    if (curPoint !== undefined)
      state.camera.position.set(curPoint.x, curPoint.y, curPoint.z);
    // camera.fov = MathUtils.lerp(60, 12, scroll.offset);
    if (targetPoint !== undefined) state.camera.lookAt(targetPoint);
    state.camera.updateProjectionMatrix();

    // setScrollDirection(curPointIndex);
  });

  return;
};

export default Camera;

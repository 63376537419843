import { useThree } from "@react-three/fiber";
import { useEffect, useMemo, useRef } from "react";
import { Object3D } from "three";

import { Vector3 } from "three";
import { Environment, Lightformer } from "@react-three/drei";
import { DeskStuff } from "../components/models/Desk-stuff";

export const TableScene = (props) => {
  const ref = useRef();
  const { scene } = useThree();

  const bookTarget = useMemo(() => {
    return new Object3D();
  }, []);

  useEffect(() => {
    scene.add(bookTarget);
    const target = new Vector3();
    ref.current.children.forEach((element) => {
      if (element.name === "target") return element.getWorldPosition(target);
    });

    bookTarget.position.set(target.x, target.y, target.z);
  }, [bookTarget, scene]);

  return (
    <group ref={ref} {...props}>
      <Environment preset="city">
        <Lightformer
          form="ring"
          position={[-13, -12, 13]}
          intensity={25} // power level (optional = 1)
          color="white" // (optional = white)
          scale={[10, 5]} // Scale it any way you prefer (optional = [1, 1])
        />
      </Environment>
      <DeskStuff
        rotation={[Math.PI / 2, 0, 0]}
        scale={1.6}
        position={[-2.2, 1, 0]}
      />
      <group name="angle-poise" position={[-4.5, 0, 0]} scale={1.2}>
        <group name="light rig" position={[1.3, 0.2, 4]}>
          {/* <Sphere args={[0.1]} material-color="hotpink" /> */}
          <spotLight
            intensity={6}
            distance={15}
            castShadow
            angle={0.64}
            target={bookTarget}
            penumbra={0.8}
          />
        </group>
      </group>
      {props.children}
    </group>
  );
};

import { Html, useProgress } from "@react-three/drei";
import { round } from "lodash";
import {
  Icon1,
  Icon10,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
  Icon8,
  Icon9,
} from "./icons";

export const LoadingScreen = () => {
  const { progress } = useProgress();

  return (
    <Html center>
      <div className="loadingscreen">
        <div className="icons">
          <Icon1 />
          <Icon2 />
          <Icon3 />
          <Icon4 />
          <Icon5 />
          <Icon6 />
          <Icon7 />
          <Icon8 />
          <Icon9 />
          <Icon10 />
        </div>
        <div className="loadingscreen_progress">
          <div
            className="loading-progress"
            style={{ width: `${progress * 2}px` }}
          />

          <p style={{ color: "white" }}>{round(progress, 0)}%</p>
        </div>
      </div>
    </Html>
  );
};

import { Canvas } from "@react-three/fiber";
import "./App.css";
import {
  PerformanceMonitor,
  ScrollControls,
  useTexture,
} from "@react-three/drei";
import Camera from "./components/camera";
import { followPoints, useNoOfPoints } from "./components/data/curve";
import { ScrollText } from "./components/html/scenes";
import { FairyTaleScene } from "./scenes/FairyTale";
import { NewBook } from "./components/models/Book-2";
import { Pirates } from "./scenes/Pirates";
import { TableScene } from "./scenes/Table";

import { Suspense, useEffect, useState } from "react";
import { Logo } from "./components/html/logo";
import useMediaQuery from "./components/hooks/useMediaQuery";
import { round } from "lodash";
import { Close, Info, Mail } from "./components/html/icons";
import { LoadingScreen } from "./components/html/loading-screen";

const ScrollScene = () => {
  const planner = useTexture("planner.jpg");
  return (
    <TableScene position={followPoints[1]}>
      <NewBook
        scale={[1.3, 1.2, 1.2]}
        blendThreshold={0.133}
        opensAt={0.01}
        text={"We know that complex stories are difficult to tell..."}
        chapter={1}
        duration={1}
        tableColor="white"
        collarColor="hsl(252, 22%, 34%)"
      >
        <FairyTaleScene position={followPoints[1]} scale={0.5}>
          <TableScene position={followPoints[3]}>
            <NewBook
              opensAt={0.22}
              duration={1.23}
              rotation={[0, 0, 0.3]}
              blendThreshold={0.414}
              chapter={2}
              text={"That’s why Cornell Box work alongside agency partners..."}
              scale={[1.7, 1.2, 1.2]}
              tableColor="white"
              collarColor="black"
            >
              <Pirates position={followPoints[5]}>
                <group position={followPoints[followPoints.length - 1]}>
                  <TableScene
                    position={[1, -5, 0]}
                    rotation={[-Math.PI / 2, 0, 0]}
                  >
                    <mesh
                      name="target"
                      position={[-0.5, 0, 0.1]}
                      rotation={[0, 0, -0.12]}
                      receiveShadow
                    >
                      <planeGeometry args={[3, 3 / 1.42]} />
                      <meshBasicMaterial map={planner} toneMapped={false} />
                    </mesh>
                  </TableScene>
                </group>
              </Pirates>
            </NewBook>
          </TableScene>
        </FairyTaleScene>
      </NewBook>
    </TableScene>
  );
};

function App() {
  const [modal, modalState] = useState(false);
  const [info, infoState] = useState(false);
  const [dpr, setDpr] = useState(1);
  const mobile = useMediaQuery("(min-width: 960px)");

  function setModal() {
    infoState(false);
    modalState(!modal);
  }
  function setInfo() {
    modalState(false);
    infoState(!info);
  }
  useEffect(() => {
    if (mobile)
      document
        .querySelector("meta[name=viewport]")
        .setAttribute(
          "content",
          "width=device-width, initial-scale=" + 1 / window.devicePixelRatio
        );
  }, [mobile]);
  return (
    <div id="app">
      <nav>
        <a href="/" aria-label="home">
          <Logo />
        </a>
        <div style={{ width: "100%" }} />
        <button
          onClick={() => setInfo()}
          aria-label="information"
          //className={`${info ? "not" : "visible"}`}
        >
          {info ? <Close /> : <Info />}
        </button>

        <button
          onClick={() => setModal()}
          aria-label="contact-us"
          //className={`${modal ? "not" : "visible"}`}
        >
          {modal ? <Close /> : <Mail />}
        </button>
      </nav>
      <Canvas
        className="canvas"
        shadows
        linear
        // frameloop="demand"
        //turning this ^^^ on will cause animations that loop to pause when 'demand' drops, i.e. mouse not being used
        // performance={{ min: 0.5 }}
        dpr={dpr}
      >
        <Suspense fallback={<LoadingScreen />}>
          <ScrollControls
            pages={useNoOfPoints() - 1}
            damping={0.15}
            maxSpeed={2}
          >
            <ScrollScene />
            <Camera />
            <ScrollText modal={modal} info={info} />
          </ScrollControls>

          <PerformanceMonitor
            onChange={({ factor }) => setDpr(round(0.5 + 2 * factor, 1))}
          />
        </Suspense>
        {/* <LoadingScreen /> */}
      </Canvas>
    </div>
  );
}

export default App;

import { useEffect, useMemo, useRef } from "react";
import { Waves } from "../components/models/waves";
import { Object3D } from "three";
import { extend, useFrame, useThree } from "@react-three/fiber";
import { Island } from "../components/models/Island";
import { Copy } from "../components/hooks/copy";
import useMediaQuery from "../components/hooks/useMediaQuery";
import { Billboard } from "@react-three/drei";
import { Cloud } from "../components/models/Cloud";
import { PirateShip } from "../components/models/Ship";
import { followPoints } from "../components/data/curve";
import { Sunrise } from "../components/materials/sunrise";

extend({ Sunrise });

export const Pirates = (props) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const shaderRef = useRef();
  const { scene } = useThree();
  const fontSize = 0.35;

  const targetObject = useMemo(() => {
    return new Object3D();
  }, []);

  useFrame((state, delta) => (shaderRef.current.uTime += delta));

  useEffect(() => {
    scene.add(targetObject);
    //scene.fog = new Fog(0xedf0f2, 120, 250);

    targetObject.position.set(-2, 2, -130);
  }, [scene, targetObject]);

  return (
    <group {...props}>
      <group position={[0, 0, -23]}>
        <group position={[3, 0, 0]}>
          <Copy
            color={"white"}
            position={isMobile ? [3, -0.5, 42] : [3, -0.5, 42]}
            fontSize={fontSize}
            fadeThreshold={0.395}
          >
            to reduce complexity,
          </Copy>
          <Copy
            color={"white"}
            position={isMobile ? [2, -1.5, 35] : [2, -1.5, 35]}
            fontSize={fontSize}
            fadeThreshold={0.5}
          >
            select hardware,
          </Copy>
          <Copy
            color={"white"}
            position={isMobile ? [1, 0, 31] : [0, -0.5, 31]}
            fontSize={fontSize}
            fadeThreshold={0.583}
          >
            build software,
          </Copy>
          <Copy
            color={"white"}
            position={isMobile ? [2, -1, 24] : [0, -1, 24]}
            fontSize={fontSize}
            fadeThreshold={0.62}
          >
            and add content.
          </Copy>
        </group>
        <Island
          position={[
            5,
            -2,
            followPoints[followPoints.length - 1].z + 34 + 23 + 14.5,
          ]}
          scale={2}
        >
          {props.children}
        </Island>
        {/* <RowBoat position={[-7, -3, -17]} scale={1} /> */}
        {/* <PirateShip position={[-15, -3, 12]} scale={5} /> */}
        <PirateShip
          position={[45, -3, -88]}
          scale={[-5, 5, 5]}
          color={"#235166"}
        />

        <group position={[0, -8, 34]}>
          {/* <spotLight
            name="sun-light balance"
            position={[0, 6, 28]}
            intensity={1.5}
            target={targetObject}
            shadowMap={true}
            shadowMapResolution={2048}
            penumbra={0.25}
            distance={200}
            castShadow
          /> */}

          <Waves />
        </group>
        <Billboard lockX={true} name="horizon" position={[0, 0, -34]}>
          {/* <spotLight
            distance={250}
            position={[0, 23, -18]}
            intensity={3.84}
            castShadow
            target={targetObject}
            penumbra={0.75}
          /> */}
          <ambientLight />
          <Cloud
            position={[-3, 20, -85]}
            scale={4}
            rotation={[Math.PI / 2, 0, 0]}
          />

          {/* <pointLight
            name="sunlight"
            distance={250}
            position={[0, 2, -80]}
            intensity={12.4}
            color={"#F9BE28"}
          /> */}
          {/* <mesh name="sun" position={[0, 0, -105]}>
            <circleGeometry args={[35, 56]} />
            <meshStandardMaterial
              color={"#f9ae1c"}
              emissive={"#f9ae1c"}
              toneMapped={false}
            />
          </mesh> */}
          <mesh name="back of page" position={[0, 0, -118]} receiveShadow>
            <planeGeometry args={[500, 500]} />
            <sunrise ref={shaderRef} />
          </mesh>
        </Billboard>
      </group>
    </group>
  );
};

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.7 page1.glb
*/

import React, { useEffect, useLayoutEffect, useRef } from "react";
import {
  MeshDistortMaterial,
  useAnimations,
  useGLTF,
  useScroll,
} from "@react-three/drei";
import { PageMaterial } from "../components/materials/pageShader";
import { Color } from "three";
import { useFrame } from "@react-three/fiber";

import { Portal } from "../components/hooks/portal";
import { Copy } from "../components/hooks/copy";

export function FairyTaleScene(props) {
  const group = useRef();

  const spotLight = useRef();
  const castle = useRef();
  const { nodes, animations } = useGLTF("/models/page1.glb");
  const { actions } = useAnimations(animations, group);

  const DISTANCE = 0.1;
  const scroll = useScroll();

  useEffect(() => {
    actions["DoorLeftAction"].reset().play().paused = true;
    actions["DoorRightAction"].reset().play().paused = true;
  }, [actions]);

  useLayoutEffect(() => {
    const meshes = group.current.children.filter(
      (child) => child.type === "Mesh" || child.type === "Group"
    );
    meshes.map((mesh, i) => {
      if (mesh.type === "Mesh" && mesh.name !== "blanking plate") {
        mesh.material.color = new Color(`hsl(252, 22%, ${30 + i * 2.2}%)`);
      } else if (mesh.type === "Group") {
        mesh.children.forEach((element) => {
          element.material.color = new Color(`hsl(252, 22%, ${30 + i * 2.5}%)`);
        });
      }

      return mesh;
    });
  }, [group]);

  useFrame(() => {
    spotLight.current.target = castle.current;
    //animated door effect
    const doorRange = scroll.range(0.17, 0.056);

    actions["DoorLeftAction"].time =
      actions["DoorLeftAction"].getClip().duration * doorRange;
    actions["DoorRightAction"].time =
      actions["DoorRightAction"].getClip().duration * doorRange;

    //parallax effect
    const range = scroll.range(0.04, 0.12);
    const meshes = group.current.children.filter(
      (child) => child.type === "Mesh" || child.type === "Group"
    );
    meshes.map((mesh, i) => {
      if (mesh.name === "page edge") {
        mesh.position.z = -i * DISTANCE - i * range + 0.16;
      } else mesh.position.z = -i * DISTANCE - i * range;

      return mesh;
    });
  });

  return (
    <group {...props} dispose={null}>
      <group name="frame adjustment" position={[-0.15, 0.18, 0.65]} ref={group}>
        <pointLight distance={25} intensity={1.33} />
        <spotLight
          ref={spotLight}
          position={[-4, 0, 10]}
          angle={0.36}
          penumbra={0.95}
          distance={35}
          intensity={3}
          target={castle.current}
          castShadow
          shadowMap={2048}
        />
        <mesh name="page edge" geometry={nodes.Layer0.geometry} receiveShadow>
          <PageMaterial />
        </mesh>
        <mesh
          name="inter-grass area"
          geometry={nodes.Layer1_5.geometry}
          receiveShadow
        >
          <PageMaterial />
        </mesh>

        <mesh name="first row" geometry={nodes.Layer1.geometry} receiveShadow>
          <PageMaterial />
        </mesh>
        <mesh
          name="inter-grass area"
          geometry={nodes.Layer1_5.geometry}
          receiveShadow
          scale={[-1, 1, 1]}
        >
          <PageMaterial />
        </mesh>
        <mesh
          name="second row"
          geometry={nodes.Layer2.geometry}
          position={[0.4, 0, 0]}
          receiveShadow
        >
          <PageMaterial />
        </mesh>

        <mesh
          name="inter-grass area"
          geometry={nodes.Layer1_5.geometry}
          receiveShadow
        >
          <PageMaterial />
        </mesh>
        <mesh
          name="third row"
          geometry={nodes.Layer2.geometry}
          position={[-0.2, 0, 0]}
          scale={[-1, 1, 1]}
          receiveShadow
        >
          <PageMaterial />
        </mesh>
        <mesh name="fourth row" geometry={nodes.Layer3.geometry} receiveShadow>
          <PageMaterial />
        </mesh>
        <mesh
          name="inter-grass area"
          geometry={nodes.Layer1_5.geometry}
          receiveShadow
        >
          <PageMaterial />
        </mesh>
        <mesh name="fifth row" geometry={nodes.Layer4.geometry} receiveShadow>
          <PageMaterial />
        </mesh>
        <mesh
          name="sixth row"
          position={[0.6, 0, 0]}
          geometry={nodes.Layer5.geometry}
          receiveShadow
        >
          <PageMaterial />
        </mesh>
        <Copy fadeThreshold={0.13} position={[0.2, 0.3, 0]}>
          They need simplification,
        </Copy>

        <mesh
          name="seventh row"
          geometry={nodes.Layer4.geometry}
          position={[0.2, 0, 0]}
          scale={[1, 1, 1]}
          receiveShadow
        >
          <PageMaterial />
        </mesh>
        <mesh
          name="eighth row"
          geometry={nodes.Layer3.geometry}
          position={[1.72, 0.3, 0]}
          receiveShadow
        >
          <PageMaterial />
        </mesh>
        <Copy fadeThreshold={0.16} position={[1.4, 0, 0]}>
          to generate audience understanding.
        </Copy>
        <mesh
          name="ninth row"
          geometry={nodes.Layer5.geometry}
          position={[1.2, 0, 0]}
          scale={[1, 1, 1]}
          receiveShadow
        >
          <PageMaterial />
        </mesh>
        <mesh
          name="tenth row"
          geometry={nodes.Layer3.geometry}
          position={[-14, 0, 0]}
          scale={[-1, 1, 1]}
          receiveShadow
        >
          <PageMaterial />
        </mesh>
        <mesh
          name="eleventh row"
          geometry={nodes.Layer3.geometry}
          position={[1, 0.5, 0]}
          scale={[-1, 1, 1]}
          receiveShadow
        >
          <PageMaterial />
        </mesh>
        <mesh
          name="Hill 1"
          geometry={nodes.Hill1.geometry}
          position={[5, 0, 0]}
          receiveShadow
        >
          <PageMaterial />
        </mesh>
        <mesh
          name="Hill 2"
          geometry={nodes.Hill2.geometry}
          position={[2, 0.2, 0]}
          receiveShadow
        >
          <PageMaterial />
        </mesh>
        <group position={[1.7, 0, 0]} ref={castle}>
          <mesh name="Castle" geometry={nodes.Castle.geometry} receiveShadow>
            <PageMaterial />
          </mesh>
          <mesh
            name="DoorLeft"
            geometry={nodes.DoorLeft.geometry}
            receiveShadow
            position={[0.255, 0.19, 0]}
          >
            <PageMaterial />
          </mesh>
          <mesh
            name="DoorRight"
            position={[-0.26, 0.14, 0]}
            geometry={nodes.DoorRight.geometry}
            receiveShadow
          >
            <PageMaterial />
          </mesh>
          <Portal
            name="Portal"
            geometry={nodes.Portal.geometry}
            blendThreshold={0.2548}
          >
            {props.children}
          </Portal>
          <mesh
            name="Flag-1"
            position={[-0.04, -0.1, 0]}
            rotation={[0, 0.4, 0]}
            geometry={nodes.Flag.geometry}
            receiveShadow
          >
            <MeshDistortMaterial distort={0.2} speed={10} />
          </mesh>
          <mesh
            name="Flag-2"
            position={[1.42, -0.1, 0]}
            rotation={[0, 0.4, 0]}
            geometry={nodes.Flag.geometry}
            receiveShadow
          >
            <MeshDistortMaterial distort={0.2} speed={10} />
          </mesh>
        </group>
        <mesh
          name="blanking plate"
          geometry={nodes.Blanking.geometry}
          position={[1.5, 0.4, 0]}
        >
          <PageMaterial />
        </mesh>
      </group>
    </group>
  );
}

useGLTF.preload("/models/page1.glb");

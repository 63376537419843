/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.10 book-2.glb 
*/

import React, { useEffect, useMemo, useRef } from "react";
import { useGLTF, useAnimations, useScroll } from "@react-three/drei";
import { useFrame, useGraph } from "@react-three/fiber";
import { SkeletonUtils } from "three-stdlib";
import { TextPageMaterial } from "../materials/pageShader";
import { useNoOfPoints } from "../data/curve";
import { Portal } from "../hooks/portal";
import useMediaQuery from "../hooks/useMediaQuery";

export function NewBook(props) {
  const mobile = useMediaQuery("(max-width: 480px)");
  const group = useRef();
  const pages = useNoOfPoints() - 1;
  const { scene, materials, animations } = useGLTF("/models/book-2.glb");

  const { actions } = useAnimations(animations, group);

  const scroll = useScroll();

  // Skinned meshes cannot be re-used in threejs without cloning them
  const clone = useMemo(() => SkeletonUtils.clone(scene), [scene]);
  // useGraph creates two flat object collections for nodes and materials
  const { nodes } = useGraph(clone);

  useEffect(() => {
    actions["ArmatureAction.002"].reset().play().paused = true;
  }, [actions]);

  useFrame(() => {
    const range = scroll.range(props.opensAt, props.duration / pages - 0.1);
    actions["ArmatureAction.002"].time =
      actions["ArmatureAction.002"].getClip().duration * range;
  });
  return (
    <group ref={group} {...props} dispose={null} name="target">
      <group
        name="Armature"
        position={[0.609, 0, 0.05]}
        rotation={[0, 0, Math.PI / 2]}
      >
        <primitive object={nodes.Back} />
        <skinnedMesh
          name="BookPages"
          geometry={nodes.BookPages.geometry}
          material={nodes.BookPages.material}
          skeleton={nodes.BookPages.skeleton}
          receiveShadow
        />
        <skinnedMesh
          name="BookCover"
          geometry={nodes.BookCover.geometry}
          skeleton={nodes.BookCover.skeleton}
          material={nodes.BookCover.material}
          receiveShadow
          castShadow
        />
        <skinnedMesh
          name="BookBack"
          geometry={nodes.BookBack.geometry}
          skeleton={nodes.BookBack.skeleton}
          material={nodes.BookBack.material}
          receiveShadow
          castShadow
        />
        <skinnedMesh
          name="FirstPage"
          geometry={nodes.FirstPage.geometry}
          skeleton={nodes.FirstPage.skeleton}
          material={materials.FirstPage}
          receiveShadow
          castShadow
        />

        <skinnedMesh
          name="FirstPageText"
          geometry={nodes.FirstPageText.geometry}
          material={materials.FirstPage}
          skeleton={nodes.FirstPageText.skeleton}
          receiveShadow
          castShadow
        >
          {!mobile && (
            <meshStandardMaterial attach={"material"}>
              <TextPageMaterial {...props} />
            </meshStandardMaterial>
          )}
        </skinnedMesh>

        <Portal
          name="Picture"
          position={[0, 0.609, 0]}
          rotation={[0, 0, -Math.PI / 2]}
          geometry={nodes.Picture.geometry}
          blendThreshold={props.blendThreshold}
        >
          {props.children}
        </Portal>
      </group>
    </group>
  );
}

useGLTF.preload("/models/book-2.glb");

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.10 island.glb 
*/

import React, { useEffect, useRef } from "react";
import {
  MeshDistortMaterial,
  useAnimations,
  useGLTF,
  useScroll,
} from "@react-three/drei";
import { DoubleSide } from "three";
import { Portal } from "../hooks/portal";
import { useFrame } from "@react-three/fiber";

export function Island(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/models/island.glb");
  const { actions } = useAnimations(animations, group);
  const scroll = useScroll();

  useEffect(() => {
    actions["Chest_LidAction"].reset().play().paused = true;
  }, [actions]);

  useFrame(() => {
    //animated door effect
    const doorRange = scroll.range(0.83, 0.1);

    actions["Chest_LidAction"].time =
      actions["Chest_LidAction"].getClip().duration * doorRange;
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        name="Sand"
        geometry={nodes.Sand.geometry}
        material={materials.Beach}
      >
        <meshBasicMaterial toneMapped={false} map={materials.Beach.map} />
      </mesh>
      <mesh name="Palm_trunk" geometry={nodes.Palm_trunk.geometry}>
        <meshBasicMaterial toneMapped={false} map={materials.Tree.map} />
      </mesh>
      <mesh name="PalmLeaves" geometry={nodes.PalmLeaves.geometry}>
        <MeshDistortMaterial
          attach={"material"}
          map={materials.Tree.map}
          distort={0.13}
          speed={2}
          side={DoubleSide}
        />
      </mesh>
      <group
        name="Chest Group"
        position={[0.25, 0, -0.2]}
        rotation={[0.2, 0.23, 0]}
      >
        <mesh
          name="Chest"
          geometry={nodes.Chest.geometry}
          material={nodes.Chest.material}
          position={[-0.838, 0.505, -0.021]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.418}
        />
        <mesh
          name="Chest_Lid"
          geometry={nodes.Chest_Lid.geometry}
          material={nodes.Chest_Lid.material}
          position={[-0.419, 0.923, -0.018]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.418}
        />
        <Portal
          name="Portal"
          blendThreshold={0.9763}
          geometry={nodes.Portal.geometry}
          position={[-0.841, 0.505, -0.016]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.418}
        >
          {props.children}
        </Portal>
      </group>
    </group>
  );
}

useGLTF.preload("/models/island.glb");

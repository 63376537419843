import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Helmet, HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <App>
        <Helmet>
          <title>Cornell Box Studio | Empowering Creatives</title>
          <meta
            name="description"
            content="Empowering Creatives to tell engaging stories through cutting-edge digital experiences"
          />
          <h1>Cornell Box Ltd</h1>
        </Helmet>
      </App>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

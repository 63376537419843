import { shaderMaterial } from "@react-three/drei";

export const Sunrise = shaderMaterial(
  //uniforms -  if none; include an empty object!
  {
    viewport: [window.innerWidth, window.innerHeight],
    uTime: 0,
  },
  // vertex shader vNormal = normalize( normalMatrix * normal );
  /* glsl */ `


        varying vec2 vUV;

        void main() {

            vUV = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }
        `,
  // fragment shader
  /* glsl */ `
        
        varying vec2 vUV;

        float pi = 3.141592654;
        uniform float uTime;

        vec3 colorA = vec3(254.0/255.0,188.0/255.0,46.0/255.0);
        vec3 colorB = vec3(254.0/255.0,188.0/255.0,46.0/255.0);
        vec3 colorC = vec3(252.0/255.0,192.0/255.0,34.0/255.0);
        vec3 colorD = vec3(240.0/255.0,115.0/255.0,67.0/255.0);
        vec3 colorE = vec3(235.0/255.0,102.0/255.0,71.0/255.0);
        
        void main() {
          vec2 st = (vUV * 2.0 - 1.0);

          float offset = 0.150;
          float sun = length(st) - 0.15;
          sun = smoothstep(0.0, 0.002, sun);
          float angle = atan(st.y, st.x);
          float ray = fract(10.5*(angle/pi)+ uTime/2.0) * 0.22;

          vec3 color1 = mix(colorA,colorB,smoothstep(0.0, 0.15+offset, length(st)));
          color1 = mix(color1,colorC,smoothstep(0.15+offset, 0.18+offset, length(st)));
          color1 = mix(color1,colorD,smoothstep(0.18+offset, 0.66+offset, length(st)));
          color1 = mix(color1,colorE,smoothstep(0.66+offset, 1.00, length(st)));
          

          color1 += ray;
          vec3 col = mix( colorA + 0.2,color1, sun);

          gl_FragColor = vec4(col,1.0);
        }
        `
);
